import { useState, useEffect } from "react";
import { Validation } from "../../utils/Validation";
import classNames from "classnames";
import show_pass_icon from "../../img/Auth_show_pass_icon.svg";
import hidden_pass_icon from "../../img/Auth_hidden_pass.svg";

import { getPhoneCodeAuth } from "../../Api/Auth";

const AuthByPhone = ({
  authError,
  setAuthError,
  authTypes,
  phone,
  phonePassword,
  phoneCode,
  setPasswordType,
}) => {
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [activeSMSFieldMessage, setActiveSMSFieldMessage] = useState(false);
  const [second, setSecond] = useState(60);

  const [passwordView, setPasswordView] = useState(authTypes.phonePassword);

  useEffect(() => {
    setPasswordType(passwordView ? "password" : "temporary-code");
  }, [passwordView]);

  function onGetPhoneCode(e) {
    e.preventDefault();
    const phoneNumberRegExp = /^(7)[0-9]{10}/;
    if (phone.value !== "" && phoneNumberRegExp.test(phone.value) !== false) {
      getPhoneCodeAuth(phone.value)
        .then((res) => {
          if (res.text === `User with phone number ${phone.value} not exist`) {
            setAuthError("Данного номера не существует");
          } else if (
            res.status === "failure" &&
            res.text === "Too early refreshing"
          ) {
            setAuthError("Превышен лимит запросов, попробуйте еще раз");
          } else {
            // успех
          }
        })
        .catch((err) => {
          throw new Error(err?.message || "Неизвестная ошибка");
        });
    } else {
      setAuthError("Неверный формат номера");
    }
  }

  return (
    <>
      <div className="form__login">
        {authTypes.phoneOnly && (
          <div className="auth-form__select-email-phone-container">
            <span>Телефон</span>
          </div>
        )}

        <input
          className={classNames({
            "auth__form-phone-fields": true,
            "error-border": authError.length > 0,
          })}
          type="text"
          name="phone"
          required
          placeholder={"7xxxxxxxxxx"}
          minLength="11"
          maxLength="11"
          value={phone.value}
          onChange={phone.onChange}
        />
      </div>

      {false && (
        <div
          className="auth__form-disactive-sms-code"
          onClick={() => {
            //
          }}
        >
          Повторно получить код
        </div>
      )}
      <div className="form__pass">
        {authTypes.phonePasswordAndCode && (
          <div className="auth-form__select-email-phone-container">
            Выполнить вход по:
            <p
              onClick={() => setPasswordView(true)}
              className={
                passwordView
                  ? "auth-form__select-email active"
                  : "auth-form__select-email"
              }
            >
              Паролю
            </p>
            /
            <p
              onClick={() => setPasswordView(false)}
              className={
                !passwordView
                  ? "auth-form__select-phone active"
                  : "auth-form__select-phone"
              }
            >
              SMS-коду
            </p>
          </div>
        )}

        {(passwordView || authTypes.phonePasswordOnly) &&
          authTypes.phonePassword && (
            <div className="form__pass-container">
              <img
                alt="иконка"
                className="form__pass-show-pass-icon"
                src={hiddenPassword ? show_pass_icon : hidden_pass_icon}
                onClick={() => setHiddenPassword(!hiddenPassword)}
              />

              <input
                type={hiddenPassword ? "password" : "text"}
                name="password"
                placeholder="Введите пароль"
                required
                value={phonePassword.value}
                onChange={phonePassword.onChange}
                className={classNames({
                  "error-border": authError.length > 0,
                })}
              />
            </div>
          )}
        {(!passwordView || authTypes.phoneCodeOnly) && authTypes.phoneCode && (
          <div className="auth__form-phone-sms-code-block">
            <button
              type={"button"}
              onClick={(e) => onGetPhoneCode(e)}
              className={
                passwordView
                  ? "auth__form-phone-sms-code-btn hidden"
                  : "auth__form-phone-sms-code-btn"
              }
            >
              Получить код
            </button>
            <input
              className={classNames({
                "auth__form-phone-sms-fields": true,
                // hidden: !activeSMSField,
                "error-border": authError.length > 0,
              })}
              placeholder="Код из SMS"
              type="text"
              required
              minLength="4"
              maxLength="4"
              value={phoneCode.value}
              onChange={phoneCode.onChange}
            />
            <p
              className={
                activeSMSFieldMessage
                  ? "auth__form-phone-sms-message"
                  : "auth__form-phone-sms-message hidden"
              }
            >
              Вы так и не получили код? Повторно код можно получить через{" "}
              {second} сек
            </p>
          </div>
        )}
      </div>

      {authError && <div className="form__error">{authError}</div>}
    </>
  );
};

export default AuthByPhone;

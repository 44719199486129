export const config = {
    java_api_url: "https://client.evote81.dltc.spbu.ru/api",
    admin_url: "https://admin.evote81.dltc.spbu.ru/",
    auth_api_url: "https://client.evote81.dltc.spbu.ru/auth",
    ws_connect: "wss://client.evote81.dltc.spbu.ru/ws",
    ws_pass: "client",
    ws_user: "client",
    enable_esia: false,
    confidentiality: "http://docs.cryptoveche.ru/files/cryptoveche_pk.pdf",
    type: 'main',
    realm: "REALM_CRYPTOVECHE",
};
